.home-container {
  min-height: 100vh;
  display: flex;
  padding-bottom: @small-spacer;
  background: linear-gradient(
    125deg,
    rgba(18, 115, 226, 0.4) 0%,
    rgba(18, 115, 226, 0.4) 100%
  );
}

.home-logo {
  position: absolute;
  z-index: 2;
  margin-top: @small-spacer - @unit;
}

.home-background {
  background-color: rgb(69, 137, 214);
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: -1;
}

.home {
  z-index: 1;
  text-align: center;
  align-self: center;
  margin: 0 auto;

  &__tos {
    max-width: @small-component;
    color: @color-black-65 !important;
    padding-top: @unit * 2;
  }

  // .responsive-phone({
    padding: @unit*10 @unit*2 0 @unit*2;
    align-self: flex-start;
  // });

  & > :not(:last-child) {
    margin-bottom: @small-spacer;
  }

  &__title {
    margin: 0 auto;
    // width: @medium-component;
    color: white;
    padding-bottom: @small-spacer;

    // .responsive-phone({
      width: 100%;
    // });
  }

  &__form {
    text-align: center;
    margin: 0 auto;
    &-items {
      padding: 0 @unit*2;
      display: flex;
      text-align: start;
      justify-content: center;
      // .responsive-tab-port({
        display: block;
        padding: 0;
      // });
      & > :not(:last-child) {
        margin-right: @medium-spacer;
        // .responsive-tab-port({
          margin-right: 0;
          margin-bottom: @small-spacer;
        // });
      }
      &__icon {
        margin: 0 auto;
        text-align: center;
        padding: @unit / 2;
        width: @unit*4;
      }
      &__more-button {
        text-align: right;
        & > * {
          padding: 0 @unit / 2;
        }
      }
    }

    &-search-button {
      margin: auto 0;
      text-align: center;
    }
  }
}
