.responsive-phone(@rules) {
  @media only screen and (max-width: 37.5em) {
    @rules();
  } // < 600px
}

.responsive-tab-port(@rules) {
  @media only screen and (max-width: 56.25em) {
    @rules();
  } // < 900px
}

.responsive-tab-land(@rules) {
  @media only screen and (max-width: 75em) {
    @rules();
  } // < 1200px
}

.responsive-big-desktop(@rules) {
  @media only screen and (min-width: 112.5em) {
    @rules();
  } // > 1800
}
