// select
.ant-select-borderless .ant-select-selector {
  border-color: transparent !important;
  border-bottom: @border-width solid @color-black-45 !important;
}

.ant-select-arrow {
  color: @color-black-45 !important;
}

.ant-select-selection-placeholder {
  color: @color-black-45 !important;
}

// card
.ant-card.ant-card-bordered {
  border-radius: @border-radius !important;
  border-color: @color-border !important;
}

.aqcard {
  display: inline-block;
  padding: @unit*2;
  box-shadow: 0 0 10px #0000001c;
  .responsive-phone({
    padding: 0;
  });
}

// input

input[type="text"],input[type="number"] {
  border-color: transparent !important;
  border-bottom: @border-width solid @color-black-45 !important;
}

.ant-input-affix-wrapper.ant-input-affix-wrapper-borderless.ant-input-password {
  border-color: transparent !important;
  border-bottom: @border-width solid @color-black-45 !important;
  padding-bottom: 0;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

// button
.aqbutton {
  border-radius: @border-radius;
  padding: @unit @unit*4;
  height: auto;
  white-space: normal;
}

// modal
.ant-modal-content {
  border-radius: @border-radius !important;
}
.ant-modal-header {
  border-radius: @border-radius @border-radius 0 0 !important;
}

// layout
.ant-layout {
  background: transparent;
}

// footer
.ant-layout-footer {
  padding: 0 !important;
}

html#ecwid_html body#ecwid_body .ecsp .ecwid-btn--primary:hover,
html#ecwid_html body#ecwid_body .ecwid .ecwid-btn--primary:hover {
  background-color: @primary-color !important;
}

html#ecwid_html body#ecwid_body .ecsp .ecwid-btn--primary,
html#ecwid_html body#ecwid_body .ecwid .ecwid-btn--primary {
  background-color: @primary-color !important;
  border-radius: @border-radius @border-radius !important;
}
