.results {
  padding: @small-spacer @small-spacer @medium-spacer @small-spacer;
  margin: 0 auto;
  text-align: center;

  @results-table-column-width: @unit * 20;

  & > * {
    padding-bottom: @medium-spacer;
  }

  &__disclaimer {
    color: @color-black-65 !important;
  }

  &__call-to-action {
    margin: 0 auto;
    width: @large-component;
    & > :not(:last-child) {
      font-weight: 500;
      font-size: 1.5em;
      padding: @unit * 4;
      margin-bottom: @medium-spacer;
    }

    .responsive-tab-port({
      width: 100%;
   });
  }

  &__table-wrapper {
    display: flex;
    overflow-x: auto;
    max-width: 100%;
  }

  &__table {
    display: inline-block;
    margin: 0 auto;
    text-align: start;

    &-header-row {
      border: @border-width solid @color-border;
      border-radius: @border-radius @border-radius 0 0;
      display: flex;
      &__item {
        padding: @unit*2 @unit;
        width: @results-table-column-width;
        padding-left: @small-spacer;
      }
    }

    &-row {
      display: inline-block;
      justify-content: center;
      border-left: @border-width solid @color-border;
      border-right: @border-width solid @color-border;
      overflow: hidden;
      display: flex;
      & > * {
        width: @results-table-column-width;
        padding: @unit*2 @unit;
        padding-left: @small-spacer;
        .responsive-phone({
          padding: @unit*2 @unit;
        });
      }
      &__right-divider {
        border-right: @border-width solid @color-border;
        background-color: @color-black-2;
      }
      &__first {
        width: @results-table-column-width * 1.5;
      }
      &__info {
        padding-left: @unit;
        color: @primary-color;
      }
      &:hover {
        background-color: @color-black-2;
      }
    }

    &-footer-row {
      border-bottom: @border-width solid @color-border;
      border-radius: 0 0 @border-radius @border-radius;
    }

    &-tooltip-item {
      padding: @unit;
    }
  }
}
