.page-layout {
  min-height: 100vh;
  background-color: @color-white;

  &__header {
    display: flex;
    align-items: center;
    justify-items: center;
    padding: @unit;
    &-logo {
      padding-right: @unit;
      margin-left: @small-spacer;
      &.white {
        & > * {
          color: @color-white !important;
        }
      }
      & > * {
        color: @primary-color !important;
      }
    }

    &-title {
      text-align: center;
      width: @small-component;
      padding: @unit;
      margin: 0 auto;
      font-weight: 500;
      .responsive-phone({
        width: 100%;
     });
    }
  }

  &__footer {
    text-align: center;
  }
}
