.aqitem {
  display: flex;
  padding: @unit 0;
  justify-content: center;

  &__label {
    text-align: center;
    padding: @unit / 2;
    width: @unit*4;
  }
}

.aqinput {
  width: 250px;
  .responsive-phone({
    width: 200px;
  });
}
