.typography {
  &.h1 {
    font-size: 3em;
    font-weight: 500;
    margin: 0 !important;
    &.white {
      color: @color-white;
    }
  }
  &.h2 {
    font-size: 1.5em;
    font-weight: 500;
    margin: 0 !important;
    &.white {
      color: @color-white;
    }
  }
  &.h3 {
    font-size: 1.5em;
    font-weight: 400;
  }
  &.h4 {
    font-size: 1.125em;
    font-weight: 500;
    margin: 0 !important;
  }
  &.text {
    font-size: 0.8125em;
    font-weight: 400;
  }
  &.caption {
    font-size: 0.8125em !important;
    font-weight: 400;
    color: @primary-color;
  }

  &.italic {
    font-style: italic;
  }
}

p {
  font-size: 1rem;
  font-weight: 400;
  margin: 0;
}
