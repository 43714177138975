.typography.h1 {
  font-size: 3em;
  font-weight: 500;
  margin: 0 !important;
}
.typography.h1.white {
  color: #ffffff;
}
.typography.h2 {
  font-size: 1.5em;
  font-weight: 500;
  margin: 0 !important;
}
.typography.h2.white {
  color: #ffffff;
}
.typography.h3 {
  font-size: 1.5em;
  font-weight: 400;
}
.typography.h4 {
  font-size: 1.125em;
  font-weight: 500;
  margin: 0 !important;
}
.typography.text {
  font-size: 0.8125em;
  font-weight: 400;
}
.typography.caption {
  font-size: 0.8125em !important;
  font-weight: 400;
  color: #1273e2;
}
.typography.italic {
  font-style: italic;
}
p {
  font-size: 1rem;
  font-weight: 400;
  margin: 0;
}
.ant-select-borderless .ant-select-selector {
  border-color: transparent !important;
  border-bottom: 1px solid #8c8c8c !important;
}
.ant-select-arrow {
  color: #8c8c8c !important;
}
.ant-select-selection-placeholder {
  color: #8c8c8c !important;
}
.ant-card.ant-card-bordered {
  border-radius: 1.25rem !important;
  border-color: #c7c7c7 !important;
}
.aqcard {
  display: inline-block;
  padding: 1rem;
  box-shadow: 0 0 10px #0000001c;
}
@media only screen and (max-width: 37.5em) {
  .aqcard {
    padding: 0;
  }
}
input[type="text"],
input[type="number"] {
  border-color: transparent !important;
  border-bottom: 1px solid #8c8c8c !important;
}
.ant-input-affix-wrapper.ant-input-affix-wrapper-borderless.ant-input-password {
  border-color: transparent !important;
  border-bottom: 1px solid #8c8c8c !important;
  padding-bottom: 0;
}
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}
.aqbutton {
  border-radius: 1.25rem;
  padding: 0.5rem 2rem;
  height: auto;
  white-space: normal;
}
.ant-modal-content {
  border-radius: 1.25rem !important;
}
.ant-modal-header {
  border-radius: 1.25rem 1.25rem 0 0 !important;
}
.ant-layout {
  background: transparent;
}
.ant-layout-footer {
  padding: 0 !important;
}
html#ecwid_html body#ecwid_body .ecsp .ecwid-btn--primary:hover,
html#ecwid_html body#ecwid_body .ecwid .ecwid-btn--primary:hover {
  background-color: #1273e2 !important;
}
html#ecwid_html body#ecwid_body .ecsp .ecwid-btn--primary,
html#ecwid_html body#ecwid_body .ecwid .ecwid-btn--primary {
  background-color: #1273e2 !important;
  border-radius: 1.25rem 1.25rem !important;
}
.landing-layout {
  min-height: 100vh;
}
.landing-layout__footer {
  text-align: center;
}
.page-layout {
  min-height: 100vh;
  background-color: #ffffff;
}
.page-layout__header {
  display: flex;
  align-items: center;
  justify-items: center;
  padding: 0.5rem;
}
.page-layout__header-logo {
  padding-right: 0.5rem;
  margin-left: 2rem;
}
.page-layout__header-logo.white > * {
  color: #ffffff !important;
}
.page-layout__header-logo > * {
  color: #1273e2 !important;
}
.page-layout__header-title {
  text-align: center;
  width: 25rem;
  padding: 0.5rem;
  margin: 0 auto;
  font-weight: 500;
}
@media only screen and (max-width: 37.5em) {
  .page-layout__header-title {
    width: 100%;
  }
}
.page-layout__footer {
  text-align: center;
}
.home-container {
  min-height: 100vh;
  display: flex;
  padding-bottom: 2rem;
  background: linear-gradient(125deg, rgba(18, 115, 226, 0.4) 0%, rgba(18, 115, 226, 0.4) 100%);
}
.home-logo {
  position: absolute;
  z-index: 2;
  margin-top: 1.5rem;
}
.home-background {
  background-color: #4589d6;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: -1;
}
.home {
  z-index: 1;
  text-align: center;
  align-self: center;
  margin: 0 auto;
  padding: 5rem 1rem 0 1rem;
  align-self: flex-start;
}
.home__tos {
  max-width: 25rem;
  color: #595959 !important;
  padding-top: 1rem;
}
.home > :not(:last-child) {
  margin-bottom: 2rem;
}
.home__title {
  margin: 0 auto;
  color: white;
  padding-bottom: 2rem;
  width: 100%;
}
.home__form {
  text-align: center;
  margin: 0 auto;
}
.home__form-items {
  padding: 0 1rem;
  display: flex;
  text-align: start;
  justify-content: center;
  display: block;
  padding: 0;
}
.home__form-items > :not(:last-child) {
  margin-right: 4rem;
  margin-right: 0;
  margin-bottom: 2rem;
}
.home__form-items__icon {
  margin: 0 auto;
  text-align: center;
  padding: 0.25rem;
  width: 2rem;
}
.home__form-items__more-button {
  text-align: right;
}
.home__form-items__more-button > * {
  padding: 0 0.25rem;
}
.home__form-search-button {
  margin: auto 0;
  text-align: center;
}
.results {
  padding: 2rem 2rem 4rem 2rem;
  margin: 0 auto;
  text-align: center;
}
.results > * {
  padding-bottom: 4rem;
}
.results__disclaimer {
  color: #595959 !important;
}
.results__call-to-action {
  margin: 0 auto;
  width: 50rem;
}
.results__call-to-action > :not(:last-child) {
  font-weight: 500;
  font-size: 1.5em;
  padding: 2rem;
  margin-bottom: 4rem;
}
@media only screen and (max-width: 56.25em) {
  .results__call-to-action {
    width: 100%;
  }
}
.results__table-wrapper {
  display: flex;
  overflow-x: auto;
  max-width: 100%;
}
.results__table {
  display: inline-block;
  margin: 0 auto;
  text-align: start;
}
.results__table-header-row {
  border: 1px solid #c7c7c7;
  border-radius: 1.25rem 1.25rem 0 0;
  display: flex;
}
.results__table-header-row__item {
  padding: 1rem 0.5rem;
  width: 10rem;
  padding-left: 2rem;
}
.results__table-row {
  display: inline-block;
  justify-content: center;
  border-left: 1px solid #c7c7c7;
  border-right: 1px solid #c7c7c7;
  overflow: hidden;
  display: flex;
}
.results__table-row > * {
  width: 10rem;
  padding: 1rem 0.5rem;
  padding-left: 2rem;
}
@media only screen and (max-width: 37.5em) {
  .results__table-row > * {
    padding: 1rem 0.5rem;
  }
}
.results__table-row__right-divider {
  border-right: 1px solid #c7c7c7;
  background-color: #f5f5f5;
}
.results__table-row__first {
  width: 15rem;
}
.results__table-row__info {
  padding-left: 0.5rem;
  color: #1273e2;
}
.results__table-row:hover {
  background-color: #f5f5f5;
}
.results__table-footer-row {
  border-bottom: 1px solid #c7c7c7;
  border-radius: 0 0 1.25rem 1.25rem;
}
.results__table-tooltip-item {
  padding: 0.5rem;
}
.products {
  padding: 2rem 2rem 4rem 2rem;
  margin: 0 auto;
  text-align: center;
}
.products__table-wrapper {
  display: flex;
  overflow-x: auto;
  max-width: 100%;
}
.products__table {
  display: inline-block;
  margin: 0 auto;
}
.products__table > * {
  display: flex;
  margin: 0 auto;
  width: 50rem;
  border-left: 1px solid #c7c7c7;
  border-right: 1px solid #c7c7c7;
  overflow: hidden;
  background-color: #f5f5f5;
}
.products__table-divider-row {
  align-items: center;
  text-align: start;
  background-color: #ffffff;
}
.products__table-divider-row__title {
  min-width: 20rem;
  border-right: 1px solid #c7c7c7;
  padding: 0.5rem 0 0.5rem 2rem;
  background-color: #f5f5f5;
}
.products__table-divider-row__divider {
  border-top: 1px solid #c7c7c7;
  min-width: 30rem;
}
.products__table-item-row {
  display: flex;
  align-items: center;
  background-color: #ffffff;
}
.products__table-item-row > :first-child {
  background-color: #f5f5f5;
  min-width: 20rem;
  border-right: 1px solid #c7c7c7;
  padding: 0.5rem 0.5rem 0.5rem 2rem;
  text-align: start;
}
.products__table-item-row > * {
  min-width: 10rem;
}
.products__table-item-row:hover {
  background-color: #f5f5f5;
}
.products__table-header-row {
  border-left: 1px solid #c7c7c7;
  border-right: 1px solid #c7c7c7;
  border-top: 1px solid #c7c7c7;
  border-radius: 1.25rem 1.25rem 0 0;
  display: flex;
}
.products__table-header-row > :first-child {
  margin-left: calc((10rem * 2) - 1px);
  border-left: 1px solid #c7c7c7;
}
.products__table-header-row > * {
  cursor: pointer;
  background-color: #ffffff;
  min-width: 10rem;
  padding: 1rem 1rem 0 1rem;
}
.products__table-header-row__image {
  height: 7rem;
}
.products__table-header-row__image img {
  width: 8rem;
}
.products__table-footer-row {
  border-left: 1px solid #c7c7c7;
  border-right: 1px solid #c7c7c7;
  border-bottom: 1px solid #c7c7c7;
  border-radius: 0 0 1.25rem 1.25rem;
  display: flex;
}
.products__table-footer-row > :first-child {
  margin-left: calc((10rem * 2) - 1px);
  border-left: 1px solid #c7c7c7;
}
.products__table-footer-row > * {
  background-color: #ffffff;
  min-width: 10rem;
  padding: 1rem;
}
.product {
  padding-top: 4rem;
  padding-bottom: 4rem;
  display: flex;
  align-items: center;
  justify-content: space-around;
}
@media only screen and (max-width: 37.5em) {
  .product {
    display: block;
  }
}
.product__image {
  width: 37.5rem;
}
@media only screen and (max-width: 37.5em) {
  .product__image {
    padding: 0 1rem;
    width: 100%;
  }
}
.product__properties {
  text-align: start;
}
@media only screen and (max-width: 37.5em) {
  .product__properties {
    padding: 0 1rem;
  }
}
.product__properties-title {
  padding-bottom: 2rem;
}
@media only screen and (max-width: 37.5em) {
  .product__properties-title {
    text-align: center;
    padding-top: 2rem;
  }
}
.product__properties-item {
  width: 25rem;
  display: flex;
  align-items: flex-start;
  padding-bottom: 1rem;
}
.product__properties-item-icon {
  padding-top: 0.25rem;
  padding-right: 1rem;
}
@media only screen and (max-width: 37.5em) {
  .product__properties-item {
    width: 100%;
  }
}
.product__order {
  padding-top: 2rem;
  text-align: center;
}
.product__order > :not(:last-child) {
  padding-bottom: 0.5rem;
}
.product__order-modal {
  text-align: center;
}
.product__order-modal__image {
  width: 60%;
}
.product__order-modal > :not(:last-child) {
  padding-bottom: 2rem;
}
.empty-page {
  margin: 8rem auto 0 auto;
  text-align: center;
}
.empty-page > :not(:last-child) {
  padding-bottom: 4rem;
}
.aqitem {
  display: flex;
  padding: 0.5rem 0;
  justify-content: center;
}
.aqitem__label {
  text-align: center;
  padding: 0.25rem;
  width: 2rem;
}
.aqinput {
  width: 250px;
}
@media only screen and (max-width: 37.5em) {
  .aqinput {
    width: 200px;
  }
}
.aqtooltip {
  padding: 0 0.5rem;
}
.with-tooltip {
  display: flex;
  align-items: center;
}
.aqfooter {
  height: 5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  padding: 0.5rem;
}
.aqbeta {
  border: 1px solid #ffffff;
  border-radius: 1.25rem;
  position: absolute;
  top: 2rem;
  right: 2rem;
  padding: 0.25rem 1rem;
  color: #ffffff;
  font-weight: 500;
}
