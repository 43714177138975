.products {
  padding: @small-spacer @small-spacer @medium-spacer @small-spacer;
  margin: 0 auto;
  text-align: center;

  @products-table-width: @large-component;
  @products-table-column-width: @products-table-width / 5;

  &__table-wrapper {
    display: flex;
    overflow-x: auto;
    max-width: 100%;
  }

  &__table {
    display: inline-block;
    margin: 0 auto;
    & > * {
      display: flex;
      margin: 0 auto;
      width: @large-component;
      border-left: @border-width solid @color-border;
      border-right: @border-width solid @color-border;
      overflow: hidden;
      background-color: @color-black-2;
    }

    &-divider-row {
      align-items: center;
      text-align: start;
      background-color: @color-white;

      &__title {
        min-width: @products-table-column-width * 2;
        border-right: @border-width solid @color-border;
        padding: @unit 0 @unit @unit*4;
        background-color: @color-black-2;
      }
      &__divider {
        border-top: @border-width solid @color-border;
        min-width: @products-table-column-width * 3;
      }
    }

    &-item-row {
      display: flex;
      align-items: center;
      background-color: @color-white;
      & > :first-child {
        background-color: @color-black-2;
        min-width: @products-table-column-width * 2;
        border-right: @border-width solid @color-border;
        padding: @unit @unit @unit @unit*4;
        text-align: start;
      }
      & > * {
        min-width: @products-table-column-width;
      }
      &:hover {
        background-color: @color-black-2;
      }
    }

    &-header-row {
      border-left: @border-width solid @color-border;
      border-right: @border-width solid @color-border;
      border-top: @border-width solid @color-border;
      border-radius: @border-radius @border-radius 0 0;
      display: flex;
      & > :first-child {
        margin-left: calc((@products-table-column-width * 2) - @border-width);
        border-left: @border-width solid @color-border;
      }
      & > * {
        cursor: pointer;
        background-color: @color-white;
        min-width: @products-table-column-width;
        padding: @unit*2 @unit*2 0 @unit*2;
      }
      &__image {
        height: @products-table-column-width - (@unit*6);
        & img {
          width: @products-table-column-width - (@unit*4);
        }
      }
    }

    &-footer-row {
      border-left: @border-width solid @color-border;
      border-right: @border-width solid @color-border;
      border-bottom: @border-width solid @color-border;
      border-radius: 0 0 @border-radius @border-radius;
      display: flex;
      & > :first-child {
        margin-left: calc((@products-table-column-width * 2) - @border-width);
        border-left: @border-width solid @color-border;
      }
      & > * {
        background-color: @color-white;
        min-width: @products-table-column-width;
        padding: @unit*2;
      }
    }
  }
}
