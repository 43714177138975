.product {
  padding-top: @medium-spacer;
  padding-bottom: @medium-spacer;
  display: flex;
  align-items: center;
  justify-content: space-around;

  .responsive-phone({
    display: block;
  });

  &__image {
    width: @medium-component;

    .responsive-phone({
      padding: 0 @unit * 2;
      width: 100%;
   });
  }

  &__properties {
    text-align: start;
    .responsive-phone({
      padding: 0 @unit * 2;
   });

    &-title {
      padding-bottom: @small-spacer;
      .responsive-phone({
        text-align: center;
        padding-top: @small-spacer;
      });
    }
    &-item {
      width: @small-component;
      display: flex;
      align-items: flex-start;
      padding-bottom: @unit*2;
      &-icon {
        padding-top: @unit / 2;
        padding-right: @unit*2;
      }
      .responsive-phone({
        width: 100%;
     });
    }
  }

  &__order {
    padding-top: @small-spacer;
    text-align: center;
    & > :not(:last-child) {
      padding-bottom: @unit;
    }
  }

  &__order-modal {
    text-align: center;
    &__image {
      width: 60%;
    }
    & > :not(:last-child) {
      padding-bottom: @small-spacer;
    }
  }
}
